// clone default date extension
// which only fallbacks to minimum possible year
$.extend(window.Inputmask.prototype.defaults.aliases, {
  birthdate: $.extend(
    true,
    {},
    window.Inputmask.prototype.defaults.aliases['dd.mm.yyyy'],
    {
      determinebaseyear: (minyear) => minyear,
      alias: 'dd.mm.yyyy',
    },
  ),
})
