$.fn.select2.amd.define('medods/select2/ajaxWithPreload',
  [
    'select2/data/ajax',
    'select2/data/array',
    'select2/utils',
  ], function (AjaxData, ArrayData, Utils) {
    function AjaxWithPreload ($element, options) {
      AjaxWithPreload.__super__.constructor.call(this, $element, options)
    }

    Utils.Extend(AjaxWithPreload, AjaxData)
    AjaxWithPreload.prototype.query = function (params, callback) {
      params.term = params.term || ''
      if (!params.term.length) {
        ArrayData.prototype.query.call(this, params, callback)
      } else {
        AjaxData.prototype.query.call(this, params, callback)
      }
    }

    return AjaxWithPreload
  }
)
